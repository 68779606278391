$baseWhite: #fff;
$lightBlack: #2B2B2B;
$baseBlack: #1C1C1C;
$baseBlue: #75AFF9;
$basePurple: #B16FF9; 
$baseGray: #C6C6C6;
$secondaryGray: #B3B3B3;
$secondaryBlue: #98C5FF;
$baseBack: #FBFBFB;
$thirdGray: #A4A4A4;
$baseRed: #FC6E6E;
$secondaryBlack: #2B2B2B;
$baseBackText:#F1F1F1;