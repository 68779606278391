.global-slick-container{
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 15px;
    left: 0;
    pointer-events: none;
    @media(max-width:1650px){
        bottom: 10px;
    }
    @media(max-width:1300px){
        display: none;
    }
    &.portfolio__slick{
        bottom: 180px;
        @media(max-width:1650px){
            bottom: 160px;
        }
        .global-slick-text{
            color: #2F2F2F;   
        }
    }
}
.global-slick-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 180px;
    line-height: 219px;
    color: $baseBackText;
    white-space: nowrap;
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    p{
        margin: 0 20px;
    }
    @media(max-width:1650px){
        font-size: 100px;
        line-height: 130px;
    }
    @media(max-width:1500px){
        font-size: 80px;
        line-height: 100px;
    }
    @media(max-width:1400px){
        font-size: 60px;
        line-height: 80px;
    }
}