.homepage-technologies-section{
    padding-top: 100px;
    padding-bottom: 45px;
    @media(max-width:1300px){
        padding-top: 70px;
    }
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.technologies{
    &-title{
        margin-bottom: 90px;
        @media(max-width:1500px){
            margin-bottom: 70px;
        }
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
    }
    &__slider{
        width: 100%;
        max-width: 1680px;
        margin: 0 auto;
        position: relative;
        .swiper-slide{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            min-height: 185px;
            @media(max-width:1024px){
                min-height: 150px;
            }
        }
    }
    &__item{
        width: 100%;
        position: relative;
        height: 100%;
        transition: .3s;
        border: 1px solid #9C9C9C;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 30px;
        align-items: center;
        padding-bottom: 35px;
        @media(max-width:1024px){
            padding: 20px;
            border-radius: 6px;
        }
        &:hover{
            @media(min-width: 1025px){
                background-color: $baseWhite;
                .technologies-item__name{
                    color: $baseBlack;
                }
            }
        }
    }
    &-item__photo{
        width: 100%;
        min-height: 70px;
        max-height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
        img{
            max-width: 100%;
            width: fit-content;
            max-height: 70px;
            object-fit: contain;
        }
    }
    &-item__name{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $baseWhite;
        transition: .3s;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
        }
    }
}