.homepage-contacts-section{
    padding-top: 100px;
    padding-bottom: 0;
    z-index: 2;
    @media(max-width:1300px){
        padding-top: 80px;
    }
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.contacts{
    &-title{
        margin-bottom: 90px;
        @media(max-width:1500px){
            margin-bottom: 70px;
        }
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
    }
    &-box{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        @media(max-width:1300px){
            flex-direction: column-reverse;
            align-items: center;
        }
    }
    &-map__container {
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-bottom: 60px;
        z-index: 1;
        @media(max-width:1500px){
            width: 100%;
            max-width: 600px;
            padding-bottom: 40px;
        }
        @media(max-width:1400px){
            width: 100%;
            max-width: 500px;
            padding-bottom: 60px;
        }
        @media(max-width:1300px){
            width: 100%;
            max-width: 600px;
            padding-bottom: 60px;
        }
        @media(max-width:1024px){
            padding-bottom: 0;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .contact-moon__img{
            z-index: -1;
            width: 100%;
            height: 100%;
            max-width: 150px;
            max-height: 150px;
            object-fit: contain;
            position: absolute;
            pointer-events: none;
            top: -90px;
            right: -40px;
            animation: rotated 30s linear infinite;
            @media(max-width:1300px){
                top: -60px;
                right: -110px;
            }
            @media(max-width:1024px){
                max-width: 100px;
                max-height: 100px;
                top: -40px;
                right: 12%;
            }
            @media(max-width:700px){
                top: -30px;
                right: 0%;
            }
            @media(max-width:576px){
                max-width: 80px;
                max-height: 80px;
                top: -20px;
            }
            @media(max-width:500px){
                max-width: 60px;
                max-height: 60px;
                top: -10px;
            }
            @media(max-width:500px){
                max-width: 50px;
                max-height: 50px;
                top: -10px;
            }
            
        }
    }
    &-info {
        width: 50%;
        position: relative;
        z-index: 0;
        padding-left: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width:1500px){
            width: 100%;
            padding-left: 65px;
            max-width: calc(100% - 600px);
        }
        @media(max-width:1400px){
            width: 100%;
            padding-left: 60px;
            max-width: calc(100% - 500px);
        }
        @media(max-width:1300px){
            max-width: 100%;
            padding-left: 0;
            margin-bottom: 80px;
            z-index: 1;
        }
        @media(max-width:1024px){
            margin-bottom: 60px;
        }
        @media(max-width:700px){
            margin-bottom: 40px;
        }
    }
    &-info__list {
        width: 100%;
        max-width: 640px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
        @media(max-width:1300px){
            max-width: 100%;
        }
    }
    &-info__item {
        width: calc(50% - 20px);
        position: relative;
        margin-bottom: 40px;
        @media(max-width:1500px){
            margin-bottom: 30px;
        }
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
        @media(max-width:576px){
            width: 100%;
        }
    }
    &-info__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $baseWhite;
        margin-bottom: 25px;
        @media(max-width:1500px){
            margin-bottom: 15px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
        }
    }
    &-info__links {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        li{
            margin-bottom: 20px;
            @media(max-width:1500px){
                margin-bottom: 15px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &-info__link{
        display: inline-flex;
        align-items: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 27px;
        color: $baseWhite;
        transition: .3s;
        overflow-wrap: break-word;
        @media(max-width:1500px){
            font-size: 20px;
            line-height: 24px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
        }
        .icon{
            fill: $baseWhite;
            max-width: 20px;
            max-height: 20px;
            object-fit: contain;
            transition: .3s;
            margin-right: 10px;
            @media(max-width:1024px){
                max-width: 16px;
                max-height: 16px;
                margin-right: 7px;
            }
        }
        &.address__link span::after{
            display: none;
        }
        &.address__link:hover{
            color: $baseWhite;
            .icon{
                fill: $baseWhite;
            }
        }
        &.address__link {
            font-size: 18px;
            line-height: 22px;
            font-weight: 300;
            @media(max-width:1500px){
                font-size: 16px;
                line-height: 20px;
            }
            @media(max-width:1024px){
                font-size: 14px;
                line-height: 18px;
            }
        }

        span{
            position: relative;
        }
        span::after{
            width: 0%;
            height: 1px;
            background-color: $basePurple;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            transition: .3s;
        }
        &:hover {
            color: $basePurple;
            .icon{
                fill: $basePurple;
            }
            span::after{
                width: 100%;
            }
        }
    }
    &__socials{
        width: calc(100% + 10px);
        margin-left: -5px;
        margin-right: -5px;
        .social__item .icon{
            fill: $baseWhite;
        }
        
    }

    // form
    &-form{
        width: 100%;
        max-width: 725px;
        position: relative;
        padding: 40px 35px;
        padding-bottom: 35px;
        border: 1px solid $baseWhite;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 0;
        border-bottom-left-radius:0;
        border-bottom-right-radius:0;
        @media(max-width:1500px){
            padding: 30px;
            padding-bottom: 25px;
        }
        @media(max-width:1300px){
            max-width: 100%;
            padding: 30px;
            border-bottom: 1px solid $baseWhite;
            border-bottom-left-radius:10px;
            border-bottom-right-radius:10px;
        }
        @media(max-width:1024px){
            padding: 20px 15px;
            border-radius: 6px;
        }
    }
    &-form__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: $baseWhite;
        margin-bottom: 30px;
        @media(max-width:1500px){
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 20px;
        }
        @media(max-width:1024px){
            font-size: 20px;
            line-height: 24px;
        }
        @media(max-width:576px){
            font-size: 18px;
            line-height: 22px;
        }
    }
    &-form__btn{
        border-color: $basePurple !important;
        min-width: 400px;
        @media(max-width:576px){
            min-width: 100%;
        }
    }
}

.contact-map{
    width: 100%;
    height: 730px;
    max-width: 730px;
    max-height: 730px;
    border-radius: 50%;
    z-index: 1;
    @media(max-width:1500px){
        height: 600px;
        max-width: 600px;
    }
    @media(max-width:1400px){
        height: 500px;
        max-width: 500px;
    }
    @media(max-width:1300px){
        height: 600px;
        max-width: 600px;
    }
    @media(max-width:1024px){
        height: 550px;
        max-width: 550px;
    }
    @media(max-width:700px){
        height: 470px;
        max-width: 470px;
    }
    @media(max-width:576px){
        height: 400px;
        max-width: 400px;
    }
    @media(max-width:500px){
        height: 350px;
        max-width: 350px;
    }
    @media(max-width:450px){
        height: 300px;
        max-width: 300px;
    }
    @media(max-width:360px){
        height: 270px;
        max-width: 270px;
    }
}

.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive{
    margin-top: -54px !important;
}