.global-btn{
  cursor: pointer;
  outline: none;
  background: transparent;
  min-height: 60px;
  min-width: 420px;
  border: 1px solid $baseWhite;
  color: $baseWhite;
  border-radius: 100px;
  padding: 10px 20px;
  transition: .3s;
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  .icon{
    fill: $baseBlue;
    transition: .3s;
    margin-left: 14px;
    max-width: 36px;
    max-height: 36px;
    object-fit: contain;
  }
  .icon-long-arrow{
    width: 36px;
    height: 8px;
    margin-left: 29px;
  }
  &::after{
    content: '';
    z-index: -1;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: 50%;
    left: 50%;
    transition: .3s;
    position: absolute;
    opacity: 0;
    background: linear-gradient(269.96deg, #11BBF0 -10.21%, #D83EFF 106.46%);
    border-radius: 100px;
    transform: translate(-50%, -50%);
  }
  &:hover{
    background-color: $basePurple;
    color: $baseWhite;
    border-color: transparent;
  }
  &:hover::after{
    opacity: 1;
  }
  &.purple-outline{
    border-color: $basePurple;
    color: $basePurple;
  }
  &.purple-outline:hover{
    background: transparent;
    border-color: transparent;
    color: $baseWhite;
  }
  &.gradient-btn{
    background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
    border-color: rgba($baseWhite,0.7);
    &::before{
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
      opacity: 0.5;
      filter: blur(17px);
      border-radius: 100px;
      content: '';
      z-index: -1;
      top: 10px;
    }
  }
  &.white-btn{
    background-color: $baseWhite;
    color: $basePurple;
    .icon{
      fill: $basePurple;
    }
    &:hover{
      color: $baseWhite;
      .icon{
        fill: $baseWhite;
      }
    }
  }
  &.gradient-btn:hover{
    // background: transparent;
    border-color: transparent;
  }
  @media(max-width:1500px){
    font-size: 16px;
    line-height: 20px;
    min-height: 55px;
  }
  @media(max-width:1024px){
    min-height: 50px;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 15px;
  }
  @media(max-width:450px){
    min-width: 100%;
  }
}

// slider buttons

.global-slider-buttons {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;
  @media(max-width:1500px){
    margin-top: 40px;
  }
  @media(max-width:1024px){
    margin-top: 30px;
  }
  .slider-button{
    width: 60px;
    height: 60px;
    transition: .3s;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative;
    z-index: 1;
    border: 1px solid $baseWhite;
    border-radius: 10px;
    margin-right: 20px;
    @media(max-width:1024px){
      width: 45px;
      height: 45px;
      border-radius: 6px;
      margin-right: 10px;
    }
    &.inverted{
      border-color: $secondaryBlack;
      .icon{
        stroke: $secondaryBlack;
      }
    }
    &:last-child{
      margin-right: 0;
    }
    &.swiper-button-disabled{
      opacity:0.3;
      pointer-events: none;
    }
    &.slider-button__next {
      transform: scale(-1,1);
    }
    &::after{
      content: '';
      width: 100%;
      position: absolute;
      height: 100%;
      background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
      z-index: -1;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: .3s;
      opacity: 0;
      border-radius: 10px;
    }
    &:before{
      width: 100%;
      height: 100%;
      pointer-events: none;
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
      opacity: 0.3;
      filter: blur(15px);
      border-radius: 10px;
      opacity: 0;
      z-index: -1;
      transition: .3s;
    }
    .icon{
      width: 17px;
      height: 21px;
      stroke: $baseWhite;
      object-fit: contain;
      transition: .3s;
      @media(max-width: 1024px){
        width: 15px;
        height: 18px;
      }
    }
    &:hover::after{
      @media(min-width: 1025px){
        opacity: 1;
      }
    }
    &:hover::before{
      @media(min-width: 1025px){
        opacity: 1;
      }
    }
    &:hover{
      @media(min-width: 1025px){
        border-color: transparent;
        .icon{
          stroke: $baseWhite;
        }
      }
    }
    
  }
}

// slider buttons end

// stepper prev btn
.stepper-prev__btn{
  width: 70px;
  height: 70px;
  border: 1px solid $baseWhite;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
  transition: .3s;
  z-index: 1;
  position: relative;
  margin-right: 18px;
  @media(max-width:1024px){
    height: 50px;
    width: 50px;
    margin-right: 15px;
  }
  .icon{
    fill: $baseWhite;
    width: 36px;
    height: 9px;
    object-fit: contain;
    transition: .3s;
    transform: scale(-1,1);
    @media(max-width:1024px){
      width: 26px;
      height: 7px;
    }
  }
  &::after{
    content: '';
    z-index: -1;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: 50%;
    left: 50%;
    transition: .3s;
    position: absolute;
    opacity: 0;
    background: linear-gradient(269.96deg, #11BBF0 -10.21%, #D83EFF 106.46%);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover{
    border-color: transparent;
    &::after{
      opacity: 1;
    }
  }
}

.stepper-next__btn{
  min-height: 70px;
  min-width: 320px;
  @media(max-width:1024px){
    min-height: 50px;
  }
  @media(max-width:576px){
    min-width: calc(100% - 65px);
  }
}
// stepper prev btn end