.modal{
    &-wraper{
        width: 100%;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        height: 100vh;
        max-height: calc(var(--vh, 1vh) * 100);
        overflow: hidden;
        overflow-y: auto;
        background-color: rgba($baseWhite,0.6);
        backdrop-filter: blur(6px);
        display: flex;
        flex-direction: column;
        padding: 50px 30px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: .3s;
        @media(max-width:576px){
            padding: 40px 15px;
        }
        &.active{
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            .modal__box{
                opacity: 1;
                visibility: visible;
            }
            .quize-stepper__box{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &-form-close__btn{
        min-width: 100%;
    }
    &__box{
        margin: auto;
        width: 100%;
        opacity: 0;
        transition: .5s;
        transition-delay: .2s;
        visibility: hidden;
        max-width: 735px;
        background: $baseWhite;
        border-radius: 10px;
        position: relative;
        @media(max-width:1024px){
            max-width: 600px;
        }
        @media(max-width:576px){
            max-width: 100%;
        }
    }
    &__header{
        width: 100%;
        position: relative;
        min-height: 90px;
        background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        @media(max-width:1024px){
            min-height: 70px;
            padding: 15px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }
    }
    &__header-title{
        text-align: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
        color: $baseWhite;
        max-width: 100%;
        @media(max-width:1500px){
            font-size: 26px;
            line-height: 30px;
        }
        @media(max-width:1024px){
            font-size: 20px;
            line-height: 24px;
        }
    }
    &__close{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        cursor: pointer;
        background-color: $baseWhite;
        position: absolute;
        top: 0;
        right: 0;
        transition: .3s;
        border-radius: 50%;
        border: 2px solid $baseBlue;
        transform: translateX(15px) translateY(-17px);
        @media(max-width:1024px){
            width: 25px;
            height: 25px;
            transform: translateX(10px) translateY(-10px);
        }
        &:hover{
            border-color: $basePurple;
            .icon{
                transform: rotate(360deg);
                stroke: $basePurple;
            }
        }
        .icon{
            max-width: 16px;
            max-height: 16px;
            stroke: $baseBlue;
            stroke-width: 2.5px;
            transition: .3s;
            @media(max-width:1024px){
                max-width: 12px;
                max-height: 12px;
            }
        }
        &:hover{
            .icon{
                fill: $basePurple;
            }
        }
    }

    &__content {
        width: 100%;
        position: relative;
        background-color: $secondaryBlack;
        padding: 40px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width:1024px){
            padding: 20px 15px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
    &__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-top: 10px;
        text-align: center;
        color: $baseWhite;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
        }
    }
    &-form{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-form__btn{
        min-width: 400px;
        border-color: $basePurple !important;
        @media(max-width:576px){
            min-width: 100%;
        }
    }
}