$ViewPort: false;
// LIBS
@import "libs";

// Custom mixins
@import 'vars/index';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

.lock{
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
}
.disable-display{
  display: none !important;
}

body {
  opacity: 0;
  transition: .5s;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1.3;
  font-size: 16px;
  scrollbar-gutter: stable;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: $baseBlack;
  background-color: $baseWhite;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, ol, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  outline: none !important;
  border-radius: 0 ;
  appearance: none;
}

button{
  cursor: pointer;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding-top: 142px;
  @media(max-width:1024px){
    padding-top: 95px;
  }
}

.global-wrapp{
  width: 100%;
  position: relative;
  padding: 40px 30px;
  background-color: $baseWhite;
  &.bg-dark{
    background-color: $secondaryBlack;
  }
  @media(max-width:1024px){
    padding: 20px 15px;
  }
}

.global-container{
  width: 100%;
  position: relative;
  max-width: 1680px;
  margin: 0 auto;
}

.global-socials{
  display: inline-flex;
  flex-wrap: wrap;
  li{
    height: fit-content;
    max-height: 20px;
    display: inline-flex;
    max-width: fit-content;
    align-items: center;
    justify-content: center;
    margin: 3px 7px;
    @media(max-width:1600px){
      margin: 3px 6px;
    }
    @media(max-width:1024px){
      margin: 5px 6px;
    }
  }
  .social__item{
    transition: .3s;
    position: relative;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    &:hover .icon{
      fill: $basePurple;
    }
    .icon{
      transition: .3s;
      fill: $baseBlack;
      max-height: 20px;
      max-width: 24px;
      object-fit: contain;
      @media(max-width:1600px){
        max-width: 22px;
      }
      @media(max-width:1500px){
        max-width: 20px;
      }
      @media(max-width:1024px){
        max-width: 22px;
      }
    }
  }
}

.global-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 59px;
  color: $secondaryBlack;
  position: relative;
  z-index: 1;
  margin-bottom: 70px;
  @media(max-width:1500px){
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 50px;
  }
  @media(max-width:1024px){
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  // @media(max-width)
  .shadow-text{
    position: absolute;
    z-index: -1;
    left: 55px;
    top: 50%;
    white-space: nowrap;
    transform: translateY(-50%);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 144px;
    line-height: 176px;
    text-transform: uppercase;
    text-shadow: -1px 0 #E8E8E8, 0 1px #E8E8E8, 1px 0 #E8E8E8, 0 -1px #E8E8E8;
    color: $baseWhite;
    @media(max-width:1500px){
      font-size:120px;
      line-height: 150px;
      left: 35px;
    }
    @media(max-width:1024px){
      font-size: 80px;
      line-height: 100px;
      left: 10px;
    }
    @media(max-width:576px){
      font-size: 60px;
      line-height: 80px;
      left: 0;
    }
  }
  &.inverted{
    color: $baseWhite;
    .shadow-text{
      text-shadow: -1px 0 #383838, 0 1px #383838, 1px 0 #383838, 0 -1px #383838;
      color: $secondaryBlack;
    }
  }
}

// .global-text{
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 300;
//   font-size: 16px;
//   line-height: 150%;
//   color: $baseBlack;
//   margin-bottom: 40px;
//   @media(max-width:1350px){
//     margin-bottom: 30px;
//   }
//   @media(max-width:1024px){
//     font-size: 14px;
//     margin-bottom: 20px;
//   }
// }

// .global-title{
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 48px;
//   line-height: 59px;
//   color: $baseBlack;
//   margin-bottom: 35px;
//   @media(max-width:1350px){
//     font-size: 40px;
//     line-height: 50px;
//     margin-bottom: 30px;
//   }
//   @media(max-width:1024px){
//     font-size: 30px;
//     line-height: 40px;
//     margin-bottom: 20px;
//   }
// }
// .global-subtitle{
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 32px;
//   line-height: 39px;
//   color: $baseBlack;
//   margin-bottom: 7px;
//   @media(max-width:1350px){
//     font-size: 28px;
//     line-height: 36px;
//   }
//   @media(max-width:1024px){
//     font-size: 24px;
//     line-height: 28px;
//     margin-bottom: 10px;
//   }
// }

button,textarea, input{
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

.content {
  flex: 1 0 auto;
}


// Modules & Components styles
@import "modules";
@import "components";
