.homepage-process-section{
    padding-top: 60px;
    padding-bottom: 50px;
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media(max-width:700px){
        padding-top: 20px;
    }
}

.process{
    &-title{
        margin-bottom: 95px;
        @media(max-width:1500px){
            margin-bottom: 80px;
        }
        @media(max-width:1300px){
            margin-bottom: 70px;
        }
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
    }

    &-box{
        width: 100%;
        position: relative;
        z-index: 1;
        max-width: 1680px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    &-buttons{
        width: fit-content;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 30px;
        order: -100;
        z-index: 1;
        @media(max-width:1600px){
            padding-bottom: 25px;
        }
        @media(max-width:1200px){
            width: 100%;
            justify-content: space-between;
            margin-bottom: 0;
        }
        @media(max-width:1024px){
            padding-bottom: 0;
        }
        @media(max-width:576px){
            margin-bottom: 15px;
        }
        &::after{
            width: calc(100% - 10px);
            z-index: -1;
            height: 1px;
            position: absolute;
            left: 50%;
            top: 51px;
            content: '';
            transform: translateX(-50%);
            border-bottom: 2px dashed $basePurple;
            @media(max-width:1600px){
                top: 44px;
            }
            @media(max-width:1350px){
                top: 34px;
            }
            @media(max-width:576px){
                top: 22px;
                border-width: 1px;
            }
            @media(max-width:350px){
                top: 19px;
            }
        }
    }

    &-tab__btn{
        width: 104px;
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 50%;
        z-index: 1;
        margin-right: 44px;
        padding: 10px;
        @media(max-width:1600px){
            width: 90px;
            height: 90px;
            margin-right: 35px;
        }
        @media(max-width:1350px){
            width: 70px;
            height: 70px;
            margin-right: 30px;
        }
        @media(max-width:1200px){
            margin-right: 20px;
        }
        @media(max-width:576px){
            width: 45px;
            height: 45px;
            margin-right: 10px;
        }
        @media(max-width:350px){
            width: 40px;
            height: 40px;
            margin-right: 7px;
        }
        
        
        &:last-child{
            margin-right: 0;
            .line{
                display: none;
            }
        }
        &:before{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
            position: absolute;
            z-index: -2;
            top: 0;
            left: 0;
            content: '';
        }
        &:after{
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border-radius: 50%;
            z-index: -1;
            background-color: $baseWhite;
            transition: .3s;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .btn__value{
            position: absolute;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            bottom: 0;
            transform: translateY(100%);
            text-align: center;
            width: 100%;
            padding-top: 15px;
            @media(max-width:1600px){
                font-size: 13px;
                line-height: 15px;
                padding-top: 10px;
            }
            @media(max-width:1350px){
                font-size: 12px;
                line-height: 14px;
                padding-top: 5px;
            }
            @media(max-width:1024px){
                display: none;
            }
        }
        .icon{
            max-width: 50px;
            max-height: 46px;
            fill: $basePurple;
            object-fit: contain;
            transition: .3s;
            @media(max-width:1600px){
                max-width: 43px;
                max-height: 40px;
            }
            @media(max-width:1350px){
                max-width: 35px;
                max-height: 30px;
            }
            @media(max-width:576px){
                max-width: 20px;
                max-height: 17px;
            }
        }
        &:hover{
            @media(min-width:1025px){
                .icon{
                    fill: $baseWhite;
                }
                &::after{
                    opacity: 0;
                }
            }
            
        }
        &.active{
            .icon{
                fill: $baseWhite;
            }
            .btn__value{
                font-weight: 700;
            }
            &::after{
                opacity: 0;
            }
        }
    }
    &__slider{
        width: 100%;
        max-width: 650px;
        position: relative;
        z-index: 1;
        order: 2;
        @media(max-width:1200px){
            max-width: 100%;
        }
        .swiper-slide {
            opacity: 0;
            transition: .3s;
            &.swiper-slide-active{
                opacity: 1;
            }
        }
    }
    &-tab__text-wrapp{
        width: 100%;
        position: relative;
        &::after{
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            background: linear-gradient(360deg, #FFFFFF -9.22%, rgba(255, 255, 255, 0.29) 24.85%);
            pointer-events: none;
        }
    }
    &-tab__text{
        width: 100%;
        position: relative;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        overflow: hidden;
        overflow-y: auto;
        color: $baseBlack;
        scrollbar-color: $baseBlack #d8d8d8;
        scrollbar-width: thin;
        margin-bottom: 15px;
        padding-right: 30px;
        padding-bottom: 15px;
        max-height: 235px;
        @media(max-width:1500px){
            font-size: 14px;
            max-height: 185px;
        }
        @media(max-width:1024px){
            padding-right: 0;
        }
        &::-webkit-scrollbar{
            width: 3px;
            background-color: #d8d8d8;
        }
        &::-webkit-scrollbar-thumb{
            width: 3px;
            background-color: $baseBlack;
        }
        ul, li{
            list-style-type: decimal;
        }
        ul{
            padding-left: 20px;
        }
    } 
    &-tab__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 44px;
        color: $secondaryBlack;
        margin-bottom: 20px;
        &.second-title{
            margin-top: 10px;
            @media(max-width:1024px){
                font-size: 24px;
                line-height: 28px;
                margin-top: 20;
            }
        }
        @media(max-width:1500px){
            font-size: 32px;
            line-height: 40px;
        }
        @media(max-width:1024px){
            font-size: 24px;
            line-height: 28px;
        }
    }
    &-tab__photos {
        position: absolute;
        // right: -280px;
        right: -17%;
        z-index: -1;
        max-width: 82%;
        max-height: 759px;
        // top: -14%;
        top: -90px;
        @media(max-width:1500px){
            // top: -10%;
            top: -70px;
        }
        @media(max-width:1200px){
            width: 100%;
            max-width: 100%;
            position: relative;
            top: unset;
            right: unset;
            order: 1;
            left: -3.5%;
            margin-bottom: 30px;
        }
        img{
            width: 100%;
            object-fit: contain;
            transition: .3s;
            pointer-events: none;
        }
    }
    &-tab__photolist {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img{
            position: absolute;
            top: 0;
            left: 0;
            transition: .3s;
            opacity: 0;
            &.active{
                opacity: 1;
            }
        }
    }
}