.footer {
  width: 100%;
  padding: 0 30px;
  background-color: $baseBlack;
  position: relative;
  @media(max-width:1024px){
    padding: 0 15px;
  }
  &__content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    @media(max-width:1024px){
      flex-wrap: wrap;
      padding: 15px 0;
      flex-direction: column;
    }
  }
  &-content-wrap{
    width: 33.333333333333333333%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    &:nth-child(3n){
      align-items: flex-end;
      padding-right: 0;
      @media(max-width:1024px){
        width: 100%;
        padding: 0;
      }
    }
    &:nth-child(3n - 2){
      align-items: flex-start;
      padding-left: 0;
      @media(max-width:1024px){
        padding: 0;
      }
    }
    @media(max-width:1024px){
      width: 100%;
      padding: 0;
      align-items: center !important;
      &.copyright-content{
        order: 10000;
      }
    }
  }

  &__copyright{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $baseWhite;
    @media(max-width:1350px){
      font-size: 14px;
      line-height: 18px;
    }
    @media(max-width:1024px){
      width: 100%;
      margin: 0;
      order: 1000;
      text-align: center;
    }
  }
  &__btn{
    min-width: 305px;
    @media(max-width:1500px){
      min-width: 260px;
    }
    @media(max-width:1100px){
      min-width: 220px;
    }
    @media(max-width:1024px){
      min-width: 330px;
      margin-bottom: 30px;
    }
    @media(max-width:450px){
      min-width: 100%;
    }
  }
  &__logo{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    @media(max-width:1024px){
      margin-bottom: 30px;
    }
    img{
      width: 50px;
      object-fit: contain;
      height: 63px;
    }
  }
}
