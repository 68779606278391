.homepage-testimonials-section{
    padding-top: 50px;
    padding-bottom: 50px;
    z-index: 1;
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.testimonials{
    &-title{
        margin-bottom: 95px;
        @media(max-width:1500px){
            margin-bottom: 75px;
        }
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
    }
    &__slider{
        width: 100%;
        max-width: 1680px;
        margin: 0 auto;
        position: relative;
        
        .swiper-container {
            overflow: visible;
            position: relative;
            z-index: 1;
            &::after{
                width: 3500px;
                height: 100%;
                // background-color: $baseWhite;
                background: linear-gradient(-90deg, #FFFFFF 88.16%, rgba(255, 255, 255, 0.29) 97.68%);
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                z-index: 2;
                transform: translate(100%,-50%);
                pointer-events: none;
                @media(max-width:1024px){
                    display: none;
                }
            }
            &::before{
                width: 3500px;
                height: 100%;
                background: linear-gradient(90deg, #FFFFFF 88.16%, rgba(255, 255, 255, 0.29) 97.68%);
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                transform: translate(-100%,-50%);
                pointer-events: none;
                @media(max-width:1024px){
                    display: none;
                }
            }
        }
        .swiper-slide{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
        }
    }
    &__item{
        width: 100%;
        position: relative;
        height: 100%;
        transition: .3s;
        background: $baseWhite;
        border: 1px solid #BFBFBF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 30px 40px;
        align-items: flex-start;
        @media(max-width:1024px){
            padding: 20px;
            border-radius: 6px;
            padding-bottom: 15px;
        }
    }
    &-item__name{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        color: $baseBlack;
        margin-bottom: 3px;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
        }
        @media(max-width:1500px){
            font-size: 22px;
            line-height: 28px;
        }
        @media(max-width:1024px){
            font-size: 18px;
            line-height: 22px;
        }
    }
    &-item__status{
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
        @media(max-width:1500px){
            font-size: 16px;
            line-height: 20px;
        }
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 15px;
        }
    }
    &-item__text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: #7E7E7E;
        margin-bottom: 25px;
        max-height: 122px;
        overflow: hidden;
        overflow-y: auto;
        scrollbar-color: $baseBlack #d8d8d8;
        scrollbar-width: thin;
        padding-right: 10px;
        &::-webkit-scrollbar{
            width: 3px;
            background-color: #d8d8d8;
        }
        &::-webkit-scrollbar-thumb{
            width: 3px;
            background-color: $baseBlack;
        }
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }
    &-item__tech{
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: auto;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-right: -10px;
        }
    }
    &-tech__photo{
        margin: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        max-height: 45px;
        @media(max-width:1024px){
            max-height: 41px;
            margin: 5px 8px;
        }
        @media(max-width:576px){
            max-height: 37px;
        }
        img{
            width: auto;
            max-width: 140px;
            max-height: 45px;
            object-fit: contain;
            @media(max-width:1024px){
                max-height: 41px;
                max-width: 120px;
            }
            @media(max-width:576px){
                max-height: 37px;
                max-width: 100px;
            }
        }
    }
}