.homepage-team-section{
    padding-top: 135px;
    padding-bottom: 190px;
    @media(max-width:1650px){
        padding-top: 120px;
        padding-bottom: 150px;
    }
    @media(max-width:1500px){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media(max-width:1200px){
        padding-top: 60px;
        padding-bottom: 50px;
    }
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
.team{
    &-title{
        margin-bottom: 60px;
        @media(max-width:1500px){
            margin-bottom: 50px;
        }
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
    }
    // global-slider
    &__slider{
        width: 100%;
        position: relative;
        z-index: 1;
        .swiper-container{
            &.global-team {
                .swiper-wrapper{
                    align-items: center;
                    @media(max-width:1200px){
                        align-items: flex-start;
                    }
                }
                .swiper-slide{
                    position: relative;
                    background-color: $secondaryBlack;
                    pointer-events: none;
                    visibility: hidden;
                    z-index: 1;
                    @media(max-width:1200px){
                        height: fit-content;
                    }
                    .team-slider__item{
                        opacity: 0 ;
                        visibility: hidden;
                        pointer-events: none;
                        transition: 1.7s !important;
                        transition-delay: 0s;
                    }
                    
                    &.swiper-slide-active{
                        visibility: visible;
                        pointer-events: all;
                        z-index: 7;
                        .team-slider__item{
                            opacity: 1;
                            transition-delay: 1.7s;
                            pointer-events: all;
                            visibility: visible;
                        }
                        .team-item-float__item {
                            display: block;
                        }
                    }
                }
            }
            overflow: visible;
        }
        .global-slider-buttons  {
            position: absolute;
            margin: 0;
            bottom: 0;
            left: 0;
            width: fit-content;
            justify-content: flex-start;
            @media(max-width:1500px){
                bottom: -20px;
            }
            @media(max-width:1200px){
                position: relative;
                left: unset;
                bottom: unset;
                justify-content: flex-end;
                width: 100%;
                margin-top: 70px;
            }
            @media(max-width:700px){
                margin-top: 30px;
            }
        }
        
    }
    &-slider__item {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width:1200px){
            flex-direction: column;
            align-items: center;
        }
    }
    // global slider end

    // info
    &-item__info {
        width: 100%;
        padding: 60px 0;
        max-width: 600px;
        min-width: 550px;
        margin-right: 50px;
        @media(max-width: 1650px){
            min-width: 500px;
            max-width: 500px;
        }
        @media(max-width: 1500px){
            min-width: 500px;
            max-width: 500px;
            margin-right: 40px;
        }
        @media(max-width:1200px){
            max-width: 100%;
            min-width: 100%;
            margin-right: 0;
            padding: 0;
            margin-bottom: 120px;
        }
        @media(max-width:700px){
            margin-bottom: 35px;
        }
    }
    &-info__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 44px;
        color: $baseWhite;
        margin-bottom: 50px;
        @media(max-width:1500px){
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 40px;
        }
        @media(max-width:1024px){
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 20px;
        }
        &.boss-title{
            margin-bottom: 10px;
        }
    }
    &-info__text{
        width: 100%;
        max-width: 100%;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: $baseWhite;
        overflow: hidden;
        overflow-y: auto;
        max-height: 290px;
        scrollbar-color: $baseWhite #595959;
        scrollbar-width: thin;
        padding-right: 40px;
        @media(max-width:1650px){
            padding-right: 25px;
        }
        @media(max-width:1500px){
            font-size: 14px;
            max-height: 270px;
        }
        @media(max-width:1200px){
            padding-right: 0;
        }
        @media(max-width:1024px){
            padding-right: 0;
        }
        @media(max-width:700px){
            max-height: 100%;
        }
        &::-webkit-scrollbar{
            width: 3px;
            background-color: #595959;
        }
        &::-webkit-scrollbar-thumb{
            width: 3px;
            background-color: $baseWhite;
        }
        h4{
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 6px;
            @media(max-width:1500px){
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 4px;
            }
            @media(max-width:1024px){
                font-size: 16px;
                line-height: 18px;
            }
        }
        *{
            max-width: 700px;
            @media(max-width:1200px){
                max-width: 100%;
            }
        }
        p b{
            font-weight: 600;
            margin-bottom: 4px;
        }
        ul {
            list-style-type:disc ;
            padding-left: 20px;
        }
        li {
            list-style-type: disc;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &-info__textwrapp {
        width: 100%;
        position: relative;
        &::after{
            width: 100%;
            max-width: 700px;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            background: linear-gradient(360deg, #2B2B2B -9.22%, rgba(43, 43, 43, 0.21) 26.24%);
            pointer-events: none;
            @media(max-width:1200px){
                max-width: 100%;
            }
            @media(max-width:700px){
                display: none;
            }
        }
    }

    &-info__status{
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 200;
        font-size: 18px;
        line-height: 22px;
        color: $baseWhite;
        margin-bottom: 30px;
        @media(max-width:1500px){
            margin-bottom: 20px;   
        }
        @media(max-width:1024px){
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 20px;
        }
    }
    // info end

    // photo
    &-item__photo {
        width: 100%;
        max-width: calc(100% - 650px);
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        @media(max-width:1650px){
            max-width: calc(100% - 550px);
        }
        @media(max-width:1500px){
            max-width: calc(100% - 540px);
        }
        @media(max-width:1200px){
            max-width: 670px;
        }
    }
    // photo slider
    &-person__slider {
        width: 100%;
        position: relative;
        visibility: visible;
        .swiper-slide{
            width: 100% !important;
            position: relative;
            max-height: 100%;
            display: flex;
            transition: .7s;
            align-items: center;
            justify-content: center;
            pointer-events: all !important;
            visibility: visible !important;
            @media(max-width:700px){
                min-height: 650px;
            }
            @media(max-width:470px){
                min-height: 450px;
            }
            @media(max-width:360px){
                min-height: 400px;
            }
        }
        .swiper-slide-active{
            img{
                opacity: 1;
            }
        }
    }
    &-item-main__photo {
        width: 100%;
        max-height: 632px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        // overflow: hidden;
        border-radius: 5px;
        @media(max-width:1650px){
            max-height: 600px;
        }
        @media(max-width:1500px){
            max-height: 550px;
        }
        @media(max-width:1200px){
            max-height: 450px;
        }
        @media(max-width:700px){
            justify-content: flex-start;
            align-items: flex-start;
            overflow: hidden;
            min-height: 650px;
            width: 100%;
            position: relative;
        }
        @media(max-width:470px){
            min-height: 450px;
        }
        @media(max-width:360px){
            min-height: 400px;
        }
        img{
            pointer-events: none;
            max-height: 632px;
            max-width: 100%;
            border-radius: 5px;
            object-fit: contain;
            opacity: 0;
            transition: 1s;
            @media(max-width:1650px){
                max-height: 600px;
            }
            @media(max-width:1500px){
                max-height: 500px;
            }
            @media(max-width:1200px){
                max-height: 450px;
            }
            @media(max-width:700px){
                object-fit: cover;
                max-height: none;
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
            }
        }
    }

    &-item-float__photo {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        pointer-events: none;
    }
    &-item-float__item {
        max-height: 400px;
        width: 100%;
        height: 100%;
        max-width: 400px;
        position: absolute;
        z-index: 3;
        cursor: pointer;
        pointer-events: all;
        z-index: 10;
        top: 0;
        left: 0;
        display: none;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        pointer-events: all;
        animation: float 20s linear infinite;
        background-color: $secondaryBlack;
        img{
            width: fit-content;
            height: 100%;
            width: 100%;
            position: absolute;
            opacity: 1;
            object-fit: cover;
            border-radius: 5px;
        }
        .main-float__photo img{
            opacity: 1;
            visibility: visible;
            z-index: 1;
            transition: 0s !important;
        }
        .new-float__photo img{
            opacity: 0;
            visibility: hidden;
            z-index: 0;
        }
        &.clicked{
            .main-float__photo img{
                opacity: 0;
                visibility: hidden;
                transition: .7s !important;
            }
            .new-float__photo img{
                opacity: 1;
                visibility: visible;
                transition: .7s !important;
            }
        }
        @media(max-width:700px){
            display: none !important;
        }
        &:nth-child(1){
            top: -140px;
            left: 10px;
            max-width: 250px;
            max-height: 330px;
            animation-delay: -10s;
            animation-duration: 17s;
            @media(max-width:1650px){
                top: -130px;
                max-width: 220px;
                max-height: 300px;
            }
            @media(max-width:1500px){
                max-width: 200px;
                max-height: 270px;
                top: -100px;

            }
            @media(max-width:1350px){
                left: 0;
                max-width: 180px;
                max-height: 250px;
                top: -80px;
            }
        }
        &:nth-child(2){
            max-width: 230px;
            max-height: 310px;
            top: unset;
            bottom: -70px;
            left: unset;
            right: 15px;
            animation-delay: -14s;
            animation-duration: 20s;
            @media(max-width:1650px){
                bottom: -60px;
                max-width: 210px;
                max-height: 280px;
            }
            @media(max-width:1500px){
                right: 10px;
                max-width: 200px;
                max-height: 270px;
                bottom: -50px;
            }
            @media(max-width:1350px){
                right: -5px;
                max-width: 180px;
                max-height: 240px;
                bottom: -40px;
            }
        }
        &:nth-child(3){
            top: unset;
            bottom: -60px;
            max-height: 300px;
            max-width: 220px;
            left:10px;
            animation-delay: -13s;
            animation-duration: 18s;
            @media(max-width:1650px){
                bottom: -50px;
                max-width: 200px;
                max-height: 270px;
            }
            @media(max-width:1500px){
                max-width: 190px;
                max-height: 260px;
                bottom: -40px;
            }
            @media(max-width:1350px){
                left: 0;
                max-width: 170px;
                max-height: 230px;
                bottom: -30px;
            }
        }
    }
    &-slider__item.team-fifth__slide{
        .team-item-main__photo {
            img{
                @media(max-width:700px){
                    object-position: 24.5%;
                }
                
            }
        }
        .team-item-float__item{   
            &:nth-child(1){
                left: -25px;
                @media(max-width:1350px){
                    left: -40px;
                    top: -120px;
                }
            }
        }
    }
}