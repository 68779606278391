.about-section{
    padding-top: 55px;
    padding-bottom: 90px;
    @media(max-width:1500px){
        padding-bottom: 60px;
    }
    @media(max-width:1024px){
        padding-top: 30px;
        padding-bottom: 40px;
    }
}

.about{
    &-title{
        margin-bottom: 70px;
        @media(max-width:1500px){
            margin-bottom: 60px;
        }
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
    }
    &-box{
        width: 100%;
        max-width: 1680px;
        position: relative;
        margin: 0 auto;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @media(max-width:1200px){
            flex-direction: column;
            align-items: center;
        }
    }
    &-info__section{
        width: 100%;
        max-width: 630px;
        min-width: 550px;
        position: relative;
        @media(max-width: 1650px){
            min-width: 500px;
            max-width: 500px;
        }
        @media(max-width: 1500px){
            min-width: 450px;
            max-width: 450px;
        }
        @media(max-width:1200px){
            max-width: 100%;
            min-width: 100%;
        }
    }
    &-info__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 44px;
        color: $secondaryBlack;
        margin-bottom: 20px;
        @media(max-width:1500px){
            font-size: 32px;
            line-height: 40px;
        }
        @media(max-width:1024px){
            font-size: 24px;
            line-height: 28px;
        }
    }

    &-info__textwrapp {
        width: 100%;
        position: relative;
        &::after{
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            background: linear-gradient(360deg, #FFFFFF -9.22%, rgba(255, 255, 255, 0.29) 24.85%);
            pointer-events: none;
            @media(max-width:1200px){
                display: none;
            }
        }
    }
    &-info__text {
        width: 100%;
        position: relative;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        overflow: hidden;
        overflow-y: auto;
        color: $secondaryBlack;
        scrollbar-color: $baseBlack #d8d8d8;
        scrollbar-width: thin;
        padding-right: 40px;
        max-height: 550px;
        @media(max-width:1650px){
            padding-right: 25px;
        }
        @media(max-width:1500px){
            font-size: 14px;
            max-height: 490px;
        }
        @media(max-width:1400px){
            max-height: 465px;
        }
        @media(max-width:1200px){
            max-height: 100%;
            padding-right: 0;
        }
        @media(max-width:1024px){
            padding-right: 0;
        }
        &::-webkit-scrollbar{
            width: 3px;
            background-color: #d8d8d8;
        }
        &::-webkit-scrollbar-thumb{
            width: 3px;
            background-color: $baseBlack;
        }
        ul, li{
            list-style-type: decimal;
        }
        ul{
            padding-left: 20px;
        }
    } 
    &-slider__section{
        width: calc(100% - 600px);
        max-width: 1000px;
        margin-right: 50px;
        padding: 50px 70px;
        @media(max-width: 1650px){
            width: calc(100% - 550px);
        }
        @media(max-width:1500px){
            width: calc(100% - 500px);
            padding: 40px 50px;
        }
        @media(max-width:1400px){
            padding: 40px;
        }
        @media(max-width:1300px){
            margin-right: 40px;
            padding: 40px 30px;
        }
        @media(max-width:1200px){
            margin-right: 0;
            width: 100%;
            max-width: 100%;
            padding: 40px;
            margin-bottom: 50px;
        }
        @media(max-width:1024px){
            padding: 0;
            padding-bottom: 20px;
            margin-bottom: 35px;
        }
        @media(max-width:576px){
            padding-bottom: 15px;
            margin-bottom: 30px;
        }
        .swiper-pagination{
            width: 100%;
            position: relative;
            // display: flex;
            display: none !important;
            z-index: 1;
            justify-content: center;
            align-items: center;
            transform: translateY(50px);
            @media(max-width:1500px){
                transform: translateY(40px);
            }
            @media(max-width:1024px){
                transform: translateY(20px);
            }
            @media(max-width:576px){
                transform: translateY(15px);
            }
        }
        .swiper-pagination-bullet{
            width: 15px;
            cursor: pointer;
            height: 15px;
            position: relative;
            z-index: 111;
            margin: 0 7px;
            border: 1px solid $basePurple;
            border-radius: 50%;
            transition: .3s;
            @media(max-width:1024px){
                width: 10px;
                height: 10px;
                margin: 0 5px;
            }
            &.swiper-pagination-bullet-active{
                background-color: $basePurple;
            }
        }
    }
    &-main__slider {
        margin: 0 auto;
        transition: 1s;
        overflow: hidden;
        .swiper-slide {
            width: 100% !important;
            transition: .3s;
            position: relative;
            min-height: 560px;
            overflow: hidden;
            border-radius: 5px;
            @media(max-width:1500px){
                min-height: 500px;
            }
            @media(max-width:1400px){
                min-height: 470px;
            }
            @media(max-width:1300px){
                min-height: 450px;
            }
            @media(max-width:1200px){
                min-height: 550px;
            }
            @media(max-width:800px){
                min-height: 450px;
            }
            @media(max-width:700px){
                min-height: 400px;
            }
            @media(max-width:600px){
                min-height: 350px;
            }
            @media(max-width:550px){
                min-height: 300px;
            }
            @media(max-width:450px){
                min-height: 250px;
            }
            @media(max-width:400px){
                min-height: 220px;
            }
            @media(max-width:350px){
                min-height: 200px;
            }
            img{
                width: auto;
                height: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                z-index: -1;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                transition: .7s;
                object-fit: contain;
                opacity: 0;
                border-radius: 5px;
                @media(max-width:1024px){
                    object-fit: cover;
                    width: 100%;
                }
            }
            &::after{
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                content: '';
                position: absolute;
                pointer-events: none;
                transition: .5s;
                z-index: 1;
                transition-delay: .3s;
            }
            &.swiper-slide-active{
                img{
                    opacity: 1;
                }
            }
        }
    }

    &-slider__floats{
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    &-float__item {
        width: 235px;
        height: 160px;
        position: absolute;
        overflow: hidden;
        pointer-events: all;
        border-radius: 5px;
        cursor: pointer;
        bottom: 0;
        right: 0;
        background-color: $baseBlack;
        transition: 1s;
        @media(max-width:1500px){
            width: 200px;
            height: 130px;
        }
        @media(max-width:1024px){
            display: none;
        }
       
        img{
            width: fit-content;
            height: 100%;
            width: 100%;
            position: absolute;
            opacity: 1;
            object-fit: cover;
            border-radius: 5px;
        }
        .main-float__photo img{
            opacity: 1;
            visibility: visible;
            z-index: 1;
            transition: 0s !important;
        }
        .new-float__photo img{
            opacity: 0;
            visibility: hidden;
            z-index: 0;
        }
        &.clicked{
            .main-float__photo img{
                opacity: 0;
                visibility: hidden;
                transition: .7s !important;
            }
            .new-float__photo img{
                opacity: 1;
                visibility: visible;
                transition: .7s !important;
            }
        }

        &:nth-child(1){
            bottom: unset;
            right: unset;
            top: 0;
            left: 0;
            animation-delay: -14s;
            animation-duration: 20s;
        }
        &:nth-child(2){
    
            bottom: 0;
            right: 0;
        }
        &:nth-child(3){
            bottom: unset;
            top: 0;
            right: 0;
            animation-delay: -12s;
            animation-duration: 17s;
        }
        &:nth-child(4){
            bottom: 0;
            right: unset;
            left: 0;
            animation-delay: -13s;
            animation-duration: 18s;
        }
    }
}