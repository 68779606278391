.error-page .content{
    display: flex;
    flex-direction: column;

}
.error-section{
    margin: auto;
}

.error{
    &__content{
        max-width: 1220px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-content__photo{
        width: 100%;
        position: relative;
        margin-bottom: 45px;
        img{
            width: 100%;
            pointer-events: none;
            object-fit: contain;
        }
        .error-text__img{
            opacity: 0.8;
            animation: blik 5s ease-in infinite;
        }
        .figure-hero-title{
            position: absolute;
            max-width: 50px;
            max-height: 50px;
            object-fit: contain;
            transition: .3s;
            z-index: 1;
            &.figure-hero-title-one{
                max-width: 37px;
                max-height: 37px;
                top: -5%;
                left: 10%;
                animation: move-on 7s linear infinite;
                @media(max-width:1024px){
                    max-width: 25px;
                    max-height: 25px;
                    animation: move-on-media 7s linear infinite;
                }
                @media(max-width:576px){
                    left: 20%;
                }
            }
            &.figure-hero-title-two{
                max-width: 30px;
                max-height: 30px;
                bottom: 20%;
                left: 17%;
                transform: rotate(20deg);
                animation: move-on 9s linear infinite;
                @media(max-width:1024px){
                    max-width: 20px;
                    max-height: 20px;
                    animation: move-on-media 9s linear infinite;
                }
                @media(max-width:576px){
                    left: 20%;
                }
            }
            &.figure-hero-title-three{
                max-width: 35px;
                max-height: 35px;
                top: 2%;
                right: 25%;
                animation: move-on 12s linear infinite;
                @media(max-width:1024px){
                    max-width: 25px;
                    max-height: 25px;
                    animation: move-on-media 12s linear infinite;
                }
            }
            &.figure-hero-title-four{
                max-width: 32px;
                max-height: 32px;
                top: 50%;
                right: 35%;
                animation: move-on 10s linear infinite;
                @media(max-width:1024px){
                    max-width: 20px;
                    max-height: 20px;
                    animation: move-on-media 10s linear infinite;
                }
                @media(max-width:576px){
                    right: 30%;
                }
            }
            &.figure-hero-title-five{
                max-width: 25px;
                max-height: 25px;
                top: 65%;
                left: -2%;
                animation: move-on 17s linear infinite;
                @media(max-width:1024px){
                    max-width: 18px;
                    max-height: 18px;
                    animation: move-on-media 17s linear infinite;
                }
                @media(max-width:576px){
                    left: 5%;
                }
            }
            &.figure-hero-title-six{
                max-width: 20px;
                max-height: 20px;
                top: 25%;
                left: 35%;
                animation: move-on 7s linear infinite;
                @media(max-width:1024px){
                    max-width: 15px;
                    max-height: 15px;
                    animation: move-on-media 7s linear infinite;
                }
            }
            &.figure-hero-title-seven{
                max-width: 50px;
                max-height: 50px;
                top: 25%;
                left: -2%;
                animation: move-on 10s linear infinite;
                @media(max-width:1024px){
                    max-width: 30px;
                    max-height: 30px;
                    animation: move-on-media 10s linear infinite;
                }
            }
            &.figure-hero-title-eight{
                max-width: 50px;
                max-height: 50px;
                top: 30%;
                right: 7%;
                transform: rotate(-50deg);
                animation: move-on 12s linear infinite;
                @media(max-width:1024px){
                    max-width: 30px;
                    max-height: 30px;
                    animation: move-on-media 12s linear infinite;
                }
                @media(max-width:576px){
                    right: 6%;
                }
            }
        }
    }
    &-content__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        text-transform: uppercase;
        background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 15px;
        @media(max-width:1500px){
            font-size: 22px;
            line-height: 26px;
        }
        @media(max-width:1024px){
            font-size: 20px;
            line-height: 24px;
        }
    }
    &-content__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $secondaryBlack;
        margin-bottom: 40px;
        @media(max-width:1500px){
            margin-bottom: 30px;
        }
        @media(max-width:1024px){
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 20px;
        }
    }
    &-content__btn{
        min-width: 425px;
        @media(max-width:500px){
            min-width: 100%;
        }
    }
}